import React from 'react';

function App() {
    return (
        <div>
            <header>
                <h1>Anand Tyagi</h1>
                <div>Fullstack developer with strong problem solving and communication skills - passionate about clean
                    software design. Mostly working with JS(React), Java(Spring boot), MongoDb+ElasticSearch and AWS
                    nowadays.

                    I have a profound interest in learning things from various disciplines and been involved with
                    projects of considerable complexity and scale.
                </div>
                <br/>
                <div>
                    Currently working with <a href="https://www.expedia.com" rel="noopener noreferrer"
                                              target="_blank">Expedia</a> on the Full stack tech of ugc platform to help
                    travellers choose the
                    right property on all Expedia group brands by Collection, Curation and Playback of user-generated
                    content(UGC).
                </div>
            </header>
            <br/>
            <div>
                <h3>Contact:</h3>
                <a href="https://www.linkedin.com/in/andynrkri" rel="noopener noreferrer" target="_blank">Linkedin</a>
            </div>
        </div>
    );
}

export default App;
